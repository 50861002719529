import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from './views/Welcome.vue'
import Info from './views/Info.vue'
import Chat from './views/Chat.vue'
import Robot from './views/Robot.vue'
import Outlay from './views/Outlay.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', component: Welcome },
  { path: '/info', component: Info, name: 'info' },
  {
    path: '/Chat',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/Robot',
    name: 'Robot',
    component: Robot,
  },
  {
    path: '/Outlay',
    name: 'Outlay',
    component: Outlay,
  }
]

const router = new VueRouter({
  routes
})

export default router

<template>
    <div class="chat-container_wrapper">
        <div class="chat-header_section">
            <div class="chat-item">
                <div class="subchat">
                    {{ displaySolar.substr(0, 4) }}年
                </div>
                <div class="subchat">
                    {{ displayLunar.getYearInGanZhi() }}年
                </div>
            </div>
            <div class="chat-item">
                <div class="subchat">{{ displaySolar.substr(5, 2) }}月</div>
                <div class="subchat">{{ displayLunar.getMonthInChinese() }}月</div>
            </div>
            <div class="chat-item">
                <div class="subchat">{{ displaySolar.substr(8, 2) }}日</div>
                <div class="subchat">{{ displayLunar.getDayInChinese() }}</div>
            </div>
        </div>

        <div class="chat-subheader_section">
            <div class="dayterm">
                <div class="dayterm_sub1">{{ getDayOfWeek(displaySolar) }}</div>
                <div class="dayterm_sub2">
                    {{ displayLunar.getFestivals().length !== 0 ? displayLunar.getFestivals()[0] :
                        displayLunar.getJieQi()
                    }}</div>
            </div>
            <div class="chat_nick" @click="cleanBirthInfoConfirm">
                <span class="iconfont icon-nvsheng" v-if="woman"></span>
                <span class="iconfont icon-nansheng" v-else></span>
                {{ nickname }}
            </div>
        </div>

        <div class="chat-main_section">
            <div class="left_text_area">
                <div class="text_title">{{ title }}</div>
                <div class=" text_area">
                    <p v-if="isNowtoday">{{ bads1 }}</p>
                    <p v-else>{{ tbads1 }}</p>
                    <p v-if="isNowtoday">{{ bads2 }}</p>
                    <p v-else>{{ tbads2 }}</p>
                    <p v-if="isNowtoday">{{ goods1 }}</p>
                    <p v-else>{{ tgoods1 }}</p>
                    <p v-if="isNowtoday">{{ goods2 }}</p>
                    <p v-else>{{ tgoods2 }}</p>
                    <p v-if="isNowtoday">{{ goods3 }}</p>
                    <p v-else>{{ tgoods3 }}</p>
                </div>
            </div>
            <div class="private_words">
                <div class="private_words_title">
                    每日秘诀
                </div>
                <div class="private_words_do">
                    <p v-if="isNowtoday">{{ everyDay }}</p>
                    <p v-else>{{ teveryDay }}</p>
                </div>
            </div>
        </div>


        <div class="calendar">
            <div class="calendar-body">
                <div class="weekdays">
                    <div v-for="day in weekDays" :key="day" class="weekday">{{ day }}</div>
                </div>
                <div class="days">
                    <div v-for="(day, index) in calendarDays" :key="index" class="day" :class="{
                        'empty': !day.date,
                        'istoday': day.isToday,
                        'istomorrow': day.istomorrow,
                        'current-month': day.currentMonth,
                        'clicked': clickedIndex === index
                    }" @click="handleDayClick(day, index)">
                        <template v-if="day.date">
                            <div class="solar-day">{{ day.date.getDate() }}</div>
                            <div class="lunar-day">{{ day.lunar }}</div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showConfirm" class="show_confirm">
            <div class=" show_confirm_content">
                <h3>是要清除生日信息么</h3>
                <div class="show_confirm_buttons">
                    <button @click="cleanBirthInfo">确认</button>
                    <button @click="cancelclean">取消</button>
                </div>
            </div>
        </div>

        <div class="chat-footer_section" @click="handleClick"
            :class="{ 'button_no_time': leftAskTime <= 0 ? true : false, button_active: isbuttonActive }">
            <div class="button_title">
                <svg class="iconluoxuan" aria-hidden="true">
                    <use xlink:href="#icon-white-Strip"></use>
                </svg>
            </div>
            <div class="button_times ">
                剩余提问次数：
                <svg class="icon-songzi" aria-hidden="true">
                    <use xlink:href="#icon-a-72"></use>
                </svg>
                X {{ leftAskTime }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/requestAPI.js'
import BarChart from '../components/BarChart.vue'
import Lunar from 'lunar-javascript'
import { mapActions } from 'vuex'
import { Toast } from 'vant'
export default {
    name: 'chat',
    data() {
        return {
            weekDays: ['日', '一', '二', '三', '四', '五', '六'],
            woman: false,
            nickname: this.$store.state.userInfo !== '' ? this.$store.state.userInfo.substr(2, 8) + "..." : window.localStorage.getItem('ssm-info').substr(2, 8) + "...",
            showConfirm: false,
            isbuttonActive: false,
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth(),
            clickedIndex: null,
            today: this.formatDate(new Date()),
            tomorrow: '',
            todayRaw: new Date(),
            tomorrowRaw: '',
            displaySolar: this.formatDate(new Date()),
            displayLunar: '',
            title: '今日提示',
            bads1: '',
            bads2: '',
            goods1: '',
            goods2: '',
            goods3: '',
            everyDay: '',
            tbads1: '',
            tbads2: '',
            tgoods1: '',
            tgoods2: '',
            tgoods3: '',
            teveryDay: '',
            isNowtoday: true,
        }
    },
    methods: {
        ...mapActions(['getAskTime']),
        handleClick() {
            if (this.leftAskTime <= 0) {
                Toast("您的提问次数不足，每天观光一下都会送一次呦")
                return
            }
            this.isbuttonActive = true;
            // 300ms后恢复原状
            axios.get(`/ssme/preAsk/${window.localStorage.getItem('ssm-info')}`).then(response => {
                console.log(response.data)
            }).catch(error => {
                console.log(error)
            })
            setTimeout(() => {
                this.isbuttonActive = false;
                this.$router.push({
                    name: 'Robot'
                }).catch(err => {
                    console.log(err)
                })
            }, 300);
        },

        handleDayClick(day, index) {
            if (!day.date) return;
            this.clickedIndex = index;
            setTimeout(() => {
                this.clickedIndex = null;
            }, 300);
            console.log(day)
            if (day.date.getDate() === this.todayRaw.getDate()) {
                this.shiftToday()
            } else if (day.date.getDate() === this.tomorrowRaw.getDate()) {
                this.shiftTomorrow()
            } else { return }
        },

        hintsAndtips() {
            Toast("本程序目前存在内容重复，数据波动等问题，请见谅。我们正在改进，谢谢支持！", 3000)
        },

        cancelclean() {
            this.showConfirm = false
        },
        prevMonth() {
            if (this.currentMonth === 0) {
                this.currentMonth = 11
                this.currentYear--
            } else {
                this.currentMonth--
            }
        },
        nextMonth() {
            if (this.currentMonth === 11) {
                this.currentMonth = 0
                this.currentYear++
            } else {
                this.currentMonth++
            }
        },
        async showAskTime() {
            if (this.$store.state.userInfo === '') {
                await this.getAskTime(window.localStorage.getItem('ssm-info'))
            } else {
                await this.getAskTime(this.$store.state.userInfo)
            }
        },
        isToday(date) {
            const today = new Date()
            return date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &
                date.getFullYear() === today.getFullYear()
        },

        isTomorrow(date) {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            return date.getDate() === tomorrow.getDate() &&
                date.getMonth() === tomorrow.getMonth() &&
                date.getFullYear() === tomorrow.getFullYear();
        },

        getLunarDate(date) {
            const lunar = Lunar.Lunar.fromDate(date)
            const lunarDay = lunar.getDayInChinese()
            // 获取节气
            const term = lunar.getJieQi()
            if (term) {
                return term
            }

            const holiday = lunar.getFestivals()
            if (holiday.length !== 0) {
                return holiday[0];
            }

            // 获取农历日期
            if (lunar.getDay() === 1) {
                return lunar.getMonthInChinese() + '月'
            }
            return lunarDay
        },
        formatDate(date, format = 'yyyy-mm-dd') {
            const map = {
                yyyy: date.getFullYear(),
                mm: String(date.getMonth() + 1).padStart(2, '0'),
                dd: String(date.getDate()).padStart(2, '0')
            };
            return format.replace(/yyyy|mm|dd/gi, matched => map[matched]);
        },
        cleanBirthInfoConfirm() {
            this.showConfirm = true
        },
        cleanBirthInfo() {
            window.localStorage.removeItem("ssm-info")
            this.$store.state.userInfo = ''
            this.showConfirm = false
            this.$router.push("/Info")
        },
        shiftToday() {
            this.displaySolar = this.today
            this.displayLunar = Lunar.Lunar.fromDate(this.todayRaw)
            this.isNowtoday = true
            this.title = '今日提示'
        },
        shiftTomorrow() {
            this.displaySolar = this.tomorrow
            this.displayLunar = Lunar.Lunar.fromDate(this.tomorrowRaw)
            if (this.tbads1 == '') {
                axios.get(`/ssme/howthisday/${window.localStorage.getItem('ssm-info')}/${this.tomorrow}`).then(response => {
                    this.tbads1 = response.data.bads1
                    this.tbads2 = response.data.bads2
                    this.tgoods1 = response.data.goods1
                    this.tgoods2 = response.data.goods2
                    this.tgoods3 = response.data.goods3
                    this.teveryDay = response.data.everyDay
                }).catch(error => {
                    console.log(error)
                })
            }
            this.isNowtoday = false
            this.title = '明日提示'

        },
        getDayOfWeek(date) {
            const days = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            return days[new Date(date).getDay()];
        }
    },

    components: {
        BarChart
    },
    beforeCreate() {
        if (this.$store.state.userInfo === '' && !window.localStorage.getItem('ssm-info')) {
            console.log(this.$store.state.userInfo)
            this.$router.push({
                name: 'info'
            })
        }
    },
    computed: {
        calendarDays() {
            const days = []
            const firstDay = new Date(this.currentYear, this.currentMonth, 1)
            const lastDay = new Date(this.currentYear, this.currentMonth + 1, 0)
            // 上个月的最后几天
            const firstDayWeekDay = firstDay.getDay()
            const prevMonthLastDay = new Date(this.currentYear, this.currentMonth, 0)
            for (let i = firstDayWeekDay - 1; i >= 0; i--) {
                const date = new Date(this.currentYear, this.currentMonth - 1, prevMonthLastDay.getDate() - i)
                days.push({
                    date,
                    lunar: this.getLunarDate(date),
                    currentMonth: false,
                    isToday: this.isToday(date),
                    istomorrow: this.isTomorrow(date)
                })
            }
            // 当前月的天数
            for (let i = 1; i <= lastDay.getDate(); i++) {
                const date = new Date(this.currentYear, this.currentMonth, i)
                days.push({
                    date,
                    lunar: this.getLunarDate(date),
                    currentMonth: true,
                    isToday: this.isToday(date),
                    istomorrow: this.isTomorrow(date)
                })
            }
            // 下个月的开始几天
            const remainingDays = 42 - days.length // 保持6行
            for (let i = 1; i <= remainingDays; i++) {
                const date = new Date(this.currentYear, this.currentMonth + 1, i)
                days.push({
                    date,
                    lunar: this.getLunarDate(date),
                    currentMonth: false,
                    isToday: this.isToday(date),
                    istomorrow: this.isTomorrow(date)
                })
            }
            return days
        },
        leftAskTime() {
            return this.$store.state.askTimes;
        }
    },
    created() {
        this.showAskTime()
        this.displayLunar = Lunar.Lunar.fromDate(new Date())
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.tomorrowRaw = tomorrow
        this.tomorrow = this.formatDate(tomorrow)
        if (this.$store.state.userInfo === '') {
            axios.get(`/ssme/howthisday/${window.localStorage.getItem('ssm-info')}/${this.today}`).then(response => {
                this.bads1 = response.data.bads1
                this.bads2 = response.data.bads2
                this.goods1 = response.data.goods1
                this.goods2 = response.data.goods2
                this.goods3 = response.data.goods3
                this.everyDay = response.data.everyDay
            }).catch(error => {
                console.log(error)
            })
            this.$store.state.userInfo = window.localStorage.getItem('ssm-info')
            this.woman = window.localStorage.getItem('ssm-info').substr(0, 1) === "0" ? true : false
        } else {
            axios.get(`/ssme/howthisday/${this.$store.state.userInfo}/${this.today}`).then(response => {
                this.bads1 = response.data.bads1
                this.bads2 = response.data.bads2
                this.goods1 = response.data.goods1
                this.goods2 = response.data.goods2
                this.goods3 = response.data.goods3
                this.everyDay = response.data.everyDay
                this.woman = this.$store.state.userInfo.substr(0, 1) === "0" ? true : false
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>

<style scoped>
.chat-subheader_section {
    height: 3vh;
    display: flex;
    line-height: 24px;
}

.dayterm {
    flex: 2;
    text-align: center;
    display: flex;
    font-weight: 500;
}

.icon-songzi {
    width: 1.5em;
    height: 1.5em;
    vertical-align: -0.25em;
    fill: currentColor;
    overflow: hidden;
}

.dayterm_sub1 {
    color: rgb(52, 52, 193);
    flex: 1;

}

.dayterm_sub2 {
    flex: 3;
    color: #670965;
}

.chat_nick {
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chat-container_wrapper {
    width: 100%;
    max-width: 600px;
    min-height: 90vh;
    padding-top: 5vh;
    box-sizing: border-box;
    margin: 0 auto;
}

.left_text_area {
    flex: 2;
    background-color: #f0f3cf;
}

.right_charts_area {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chat-item {
    flex: 1;
    justify-content: center;
    align-items: center;
}

.subchat:first-child {
    background-color: rgb(226, 113, 186);
    color: white;
}

.subchat:nth-child(2) {
    background-color: rgb(149, 217, 113);
    color: white;
}

.chat-header_section {
    height: 7vh;
    display: flex;
}

.chat-main_section {
    height: 42vh;
}

.chat-main_section_2 {
    background-color: rgba(110, 152, 207, 0.841);
}

.chat-footer_section {
    height: 4vh;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
    display: flex;
    background-color: rgb(69, 150, 133);
    color: #ffffff;
}

.subchat {
    height: 3.5vh;
    text-align: center;
    line-height: 3.5vh;
}

.text_area {
    height: 30vh;
    padding: 3px;
    box-sizing: border-box;
    overflow-wrap: anywhere;
    color: #064db0;
}

.text_title {
    height: 2.5vh;
    text-align: center;
    background-color: rgb(185, 113, 230);
    color: #ffffff;
    font-weight: 600;
}

.chat_charts {
    flex: 1;
}

.button_no_time {
    background-color: rgb(145, 155, 153);
    color: #000;
}


.button_title {
    flex: 1;
    text-align: center;
    line-height: 5vh;
}

.button_times {
    flex: 4;
    text-align: right;
    line-height: 4vh;
    color: #ffffff;
    padding-right: 5px;
    box-sizing: border-box;
}

.iconluoxuan {
    width: 1.5em;
    height: 1.5em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    animation: spining 3s linear infinite;
}

.day::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    pointer-events: none;
}

.day.clicked::after {
    animation: ripple 0.6s ease-out;
}

.istoday {
    background-color: #9fe4a1 !important;
}

.istomorrow {
    background-color: #9c91e5 !important;
}

.solar-day {
    font-size: 16px;
    font-weight: bold;
}

.lunar-day {
    font-size: 12px;
    color: #666;
}

.current-month {
    color: #333;
}

.day:not(.current-month) {
    color: #2f45a5;
}

.content-date {
    height: 30vh;
    box-sizing: border-box;
    padding: 1.5vh 2vw;
    background: linear-gradient(135deg, #fff5f5 0%, #fff8f0 100%);
    position: relative;
    font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
}

.calendar-modern-content {
    display: flex;
    height: 100%;
}

.calendar-modern-date-section {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3vw;
    max-width: 600px;
    box-sizing: border-box;
}

.calendar-modern-solar-date {
    font-size: 2.5vh;
    color: #333;
    font-weight: 600;
    margin-bottom: 1vh;
}

.calendar-modern-lunar-date {
    font-size: 2vh;
    color: #666;
    margin-bottom: 0.8vh;
}

.calendar-modern-weekday {
    font-size: 1.8vh;
    color: #888;
}

.calendar-modern-info-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1vh 2vw;
    max-width: 600px;
    box-sizing: border-box;
}

.calendar-modern-festival {
    display: flex;
    gap: 2vw;
    margin-bottom: 3px;
    margin-top: 3px;
}

.calendar-modern-solar-term,
.calendar-modern-festival-name {
    padding: 0.5vh 1.5vw;
    background: linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%);
    border-radius: 15px;
    color: white;
    font-size: 1.6vh;
}



.calendar-modern-birthday-countdown {
    font-size: 1.6vh;
    color: #666;
}

.calendar-modern-detail-btn {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
    border: none;
    color: white;
    cursor: pointer;
    width: 100px;
    height: 50px;
    border-radius: 5%;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 8px rgba(253, 160, 133, 0.3);
    margin-top: 10px;
}

.calendar-modern-detail-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(253, 160, 133, 0.4);
}

.calendar-modern-detail-btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(253, 160, 133, 0.3);
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.calendar {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    /* background-image: url("../assets/calender.png"); */
    position: relative;
    padding: 1vh 0;
    background-color: #f0f3cf;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: transparent;

}

.calendar-header button {
    padding: 5px 10px;
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    background: transparent;
    font-size: 15px;
    padding-bottom: 2px;
    color: #6b884f;
    font-weight: 600;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0 2px;
    gap: 0.5;
}

.day {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    background: transparent;
}

.day.clicked {
    transform: scale(1.1);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.05);
}

.button_active {
    background-color: #e0e0e0;
    transform: scale(0.95);
}

/* 悬停效果 */
.day:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* 点击时的涟漪效果 */
@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.show_confirm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.show_confirm_content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.show_confirm_buttons {
    margin-top: 15px;
}

.show_confirm_buttons button {
    padding: 5px 15px;
}

.private_words {
    height: 10vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.private_words_title {
    height: 2.5vh;
    text-align: center;
    background-color: rgb(185, 113, 230);
    color: #ffffff;
    font-weight: 600;
}

.private_words_do {
    flex: 7.5vh;
    color: #1f7b73;
}
</style>
<template>
    <div class="content-container">
        <!-- Text Section -->
        <div class="text-wrapper">
            {{ sampleText }}
            <div class="backToRobot" @click="zuRuck">
                返回 </div>
            </div>
            <!-- QR Code Section -->
            <div class="qrcode-container">
                <div class="qrcode-wrapper">
                    <img :src="gzh" alt="QR Code 1" class="qrcode-image">
                    <span class="qrcode-label">{{ qrcode1Label }}</span>
                </div>
                <div class="qrcode-wrapper">
                    <img :src="taobao" alt="QR Code 2" class="qrcode-image">
                    <span class="qrcode-label">{{ qrcode2Label }}</span>
                </div>
            </div>
        </div>
</template>

<script>
import gzh from '../assets/gzh.png'
import taobao from '../assets/taobao.png'
export default {
    name: 'TextWithQRCodes',
    data() {
        return {
            sampleText: '卷球個人日曆提供打造個人制定款服務，歡迎訪問我們的公眾號和淘寶店了解詳情。期待您的光臨，祝您生活愉快！',
            gzh: gzh,
            taobao: taobao,
            qrcode1Label: '卷球個人黃麗公衆號',
            qrcode2Label: '卷球周邊淘寶店鋪'
        }
    },

    methods: {
        zuRuck() {
            this.$router.push({
                name: 'Robot'
            })
        }
    }
}
</script>

<style scoped>
.content-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #bb88cb;
    min-height: 86vh;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.text-wrapper {
    background-color: #f8f9fa;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    font-size: 20px;
    line-height: 1.6;
    color: #186fc5;
    margin: 0;
    white-space: normal;
    text-indent: 1.5em;
}

.qrcode-container {
    display: flex;
    justify-content: space-around;
    gap: 24px;
    padding: 16px 0;
}

.qrcode-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.qrcode-image {
    width: 140px;
    height: 140px;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.qrcode-image:hover {
    transform: scale(1.05);
}

.qrcode-label {
    font-size: 14px;
    color: #e4e7eb;
    text-align: center;
}

.backToRobot {
    margin-top: 20px;
    color: #c2a40e;
    width: 150px;
    height: 50px;
    float: right;
    line-height: 50px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .content-container {
        padding: 16px;
    }

    .text-wrapper {
        padding: 20px;
    }

    .qrcode-image {
        width: 120px;
        height: 120px;
    }

    .qrcode-container {
        gap: 16px;
    }
}
</style>
<template>
  <footer class="footer">
    <a href="https://beian.miit.gov.cn/" class="icp-link">京ICP备2024081702号-1</a>
    &nbsp
    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044788" rel="noreferrer"
      target="_blank">京公网安备11010802044788</a>
  </footer>
</template>


<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  width: 100%;
  /* Full width */
  background-color: #f1ebe4;
  /* Background color */
  color: rgb(9, 26, 170);
  /* Text color */
  text-align: center;
  /* Center text */
  line-height: 5vh;
  height: 5vh;
  font-size: 12px;
}

a {
  color: inherit;
  /* 继承父元素的文字颜色 */
  text-decoration: none;
  /* 去除下划线 */
  background-color: transparent;
  /* 背景颜色透明 */
  font-weight: normal;
  /* 正常字体粗细 */
  font-style: normal;
  /* 正常字体样式 */
}
</style>

<template>
  <div class="form-container" :style="backgroundStyle">
    <form class="form-items" @submit.prevent="handleSubmit">
      <div class="form-group">
      </div>
      <div class="gender-switch">
        <span>男生</span>
        <label class="switch">
          <input type="checkbox" v-model="formData.gender">
          <span class="slider round"></span>
        </label>
        <span>女生</span>
      </div>
      <div class="form-group">
        <label>出生日期/时间：</label>
        <div class="date_input">
          <input id="timepicker" type="text" data-toid-date="date1" data-toid-hour="hour1" name="input_date"
            data-hour="" placeholder="请选择您的出生时间" readonly="readonly" />
          <input type="hidden" id="date1" name="date">
          <input type="hidden" id="hour1" name="hour">
        </div>
      </div>
      <div class="form-group location" style="line-height: 3vh;">
        <label>出生地：</label>
        <select v-model="formData.province" @change="onProvinceChange" required style="width:45vw;max-width:200px;"
          :style="selectstyle">
          <option value="">请选择省份</option>
          <option v-for="province in provinces" :key="province.code" :value="province.code">
            {{ province.name }}
          </option>
        </select>
        <select v-model="formData.city" @change="onCityChange" required style="float: right;width:45vw;max-width:200px;"
          :style="selectstyle">
          <option value="">请选择城市</option>
          <option v-for="city in cities" :key="city.code" :value="city">
            {{ city.name }}
          </option>
        </select>
      </div>
      <div class="map-container">
        <MapComponent ref="mapComponent" />
      </div>

      <div v-if="showCaptcha" class="captcha-modal">
        <div class="captcha-content">
          <h3>请完成验证</h3>
          <p>{{ captchaQuestion }}</p>
          <input type="number" v-model="userAnswer" placeholder="请输入答案">
          <div class="captcha-buttons">
            <button @click="verifyCaptcha">确认</button>
            <button @click="cancelCaptcha">取消</button>
          </div>
        </div>
      </div>

      <button type="submit" :style="confirmstyle">提交</button>
    </form>
  </div>
</template>

<script>
import MapComponent from '../components/MapComponent.vue'
import { provinces, cities } from '../data/contries'
import { Toast } from 'vant'
import { mapActions } from 'vuex'

export default {
  components: {
    MapComponent
  },

  data() {
    return {
      formData: {
        birthDate: '',
        birthTime: '',
        isLunar: false,
        gender: false,
        province: '',
        city: '',
        firstLog: false,
        unkntime: 0
      },
      provinces,
      cities: [],
      showMap: false,
      mapCenter: { lng: 0, lat: 0 },
      backgroundStyle: {},
      confirmstyle: {},
      selectstyle: {},
      containsSensitiveWord: false,
      showCaptcha: false,
      num1: 0,
      num2: 0,
      userAnswer: '',
      correctAnswer: 0,
      pendingSubmit: false
    };
  },
  computed: {
    captchaQuestion() {
      return `请计算: ${this.num1} + ${this.num2} = ?`
    }
  },
  methods: {
    ...mapActions(['newUser']),
    initDatepicker() {
      if (window.ruiDatepicker) {
        new window.ruiDatepicker().init('#timepicker')
      } else {
        // 如果脚本还没加载完，等待一段时间后重试
        setTimeout(() => this.initDatepicker(), 100);
      }
    },
    setVueDay(v) {
      console.log(v)
      let m = v.split("-")[1]
      let d = v.split("-")[2]
      if (parseInt(m) < 10 && parseInt(d) < 10) {
        this.formData.birthDate = v.split("-")[0] + "-" + "0" + m + "-" + "0" + d;
      } else if (parseInt(m) >= 10 && parseInt(d) < 10) {
        this.formData.birthDate = v.split("-")[0] + "-" + m + "-" + "0" + d;
      } else if (parseInt(m) < 10 && parseInt(d) >= 10) {
        this.formData.birthDate = v.split("-")[0] + "-" + "0" + m + "-" + d;
      } else {
        this.formData.birthDate = v
      }
    },
    setVueTime(v) {
      console.log(v)
      if (v === -1) {
        this.formData.birthTime = "05:00"
        this.formData.unkntime = 1
      } else {
        this.formData.birthTime = v + ":00"
        this.formData.unkntime = 0
      }
    },
    onProvinceChange() {
      if (this.formData.birthDate === '') {
        Toast('请先输入出生时间');
        this.formData.city = '';
        this.formData.province = '';
        return
      }
      this.cities = cities[this.formData.province] || [];
      this.formData.city = '';
      this.showMap = false;

    },

    async handleLogin() {
      try {
        await this.newUser({
          birtInfo: this.formData.birthDate + " " + this.formData.birthTime,
          userGend: this.formData.gender === true ? 0 : 1,
          locaProv: this.formData.province,
          locaCity: this.formData.city.code,
          cityLont: this.formData.city.lng,
          cityLagt: this.formData.city.lat,
          unknTime: this.formData.unkntime
        })
      } catch (error) {
        console.log(error)
      }
    },

    onCityChange() {
      if (this.formData.birthDate === '') {
        Toast('请先输入出生时间');
        this.formData.city = '';
        this.formData.province = '';
        return
      }
      this.showMap = true;
      const selectedCity = this.cities.find(city => city.code === this.formData.city.code);
      if (selectedCity) {
        this.$refs.mapComponent.addMarker(selectedCity.lng, selectedCity.lat, this.formData.birthDate + '日', selectedCity.name)
      }
    },

    updateBackground() {
      const date = new Date(this.formData.birthDate);
      const month = date.getMonth() + 1;
      const hour = parseInt(this.formData.birthTime);
      let season = '';
      if (month >= 3 && month <= 5) season = 'spring';
      else if (month >= 6 && month <= 8) season = 'summer';
      else if (month >= 9 && month <= 11) season = 'autum';
      else season = 'winter';
      let timeOfDay = '';
      if (hour >= 5 && hour <= 8) timeOfDay = 'brightness(1.0)';
      else if (hour > 8 && hour <= 10) timeOfDay = 'brightness(1.0)';
      else if (hour > 10 && hour <= 15) timeOfDay = 'brightness(1.1)';
      else if (hour > 15 && hour <= 19) timeOfDay = 'brightness(0.9)';
      else if (hour > 19 && hour < 24) timeOfDay = 'brightness(0.8)';
      else if (hour >= 0 && hour <= 5) timeOfDay = 'brightness(0.8)';
      else if (hour > 5 && hour <= 8) timeOfDay = 'brightness(0.9)';
      else timeOfDay = 'brightness(1.0)'
      let color = '#000'
      let fontweight = '600';
      this.backgroundStyle = {
        backgroundImage: `url(${require(`@/assets/${season}.png`)})`,
        filter: timeOfDay,
        color: color,
        fontWeight: fontweight
      };
      this.confirmstyle = {
        color: color,
        fontWeight: fontweight
      }
      this.selectstyle = {
        color: color,
        fontWeight: fontweight
      }

    },

    async submitForm() {
      await this.handleLogin()
      this.$router.push({
        name: 'Chat'
      })
    },

    generateCaptcha() {
      this.num1 = Math.floor(Math.random() * 10)
      this.num2 = Math.floor(Math.random() * 10)
      this.correctAnswer = this.num1 + this.num2
      this.userAnswer = ''
    },

    handleSubmit() {
      if (this.formData.birthDate === "") {
        Toast('请输入出生时间');
      }
      if (this.formData.birthDate !== "") {
        const today = new Date()
        const birth = new Date(this.formData.birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDiff = today.getMonth() - birth.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
          age--;
        }
        if (age < 18) {
          Toast('卷球的世界需要在18岁后进入。现在就请好好学习,加油!')
          return
        }
      }
      this.pendingSubmit = true
      this.showCaptcha = true
      this.generateCaptcha()
    },

    // 验证答案
    async verifyCaptcha() {
      if (Number(this.userAnswer) === this.correctAnswer) {
        this.showCaptcha = false
        if (this.pendingSubmit) {
          await this.submitForm()
        }
      } else {
        alert('验证答案错误，请重试！')
        this.generateCaptcha()
      }
    },
    cancelCaptcha() {
      this.showCaptcha = false
      this.pendingSubmit = false
      this.userAnswer = ''
    }
  },
  watch: {
    'formData.birthDate'() {
      this.updateBackground();
      this.formData.city = '';
      this.formData.province = '';
      this.$refs.mapComponent.removeLabel()
    },
    'formData.birthTime'() {
      this.updateBackground();
      this.formData.city = '';
      this.formData.province = '';
      this.$refs.mapComponent.removeLabel()
    }
  },
  mounted() {
    this.initDatepicker();
    this.$root.setVueDay = this.setVueDay;
    this.$root.setVueTime = this.setVueTime;
  }
};
</script>

<style scoped>
.form-container {
  margin: 0 auto;
  height: 90vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: all0.3s ease;
  background-color: #d9ceb2;
  box-sizing: border-box;
  padding: 5vh 4vw;
  background: linear-gradient(45deg,
      #e3e30a,
      #e5d30b,
      #d42a08);
  background-size: 100% 100%;
  background-attachment: fixed;
  max-width: 670px;
  min-height: 695px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="date"],
input[type="time"],
select {
  width: 94.5%;
  padding: 8px;
  border: 2px solid #ddd;
  border-radius: 4px;
}

.date-time-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.calendar-switch {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.gender-switch {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #8a7a63;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.map-container {
  margin-top: 10px;
  width: 95%;
  height: 250px;
  margin: 0 auto;
  max-width: 600px;
}

.map {
  width: 100%;
  height: 100%;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #f1ebe4;
  color: #000000;
  border: #f1ebe4 1px solid;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 2vh;
  box-sizing: border-box;

}

button:hover {
  background-color: #3e311e;
}

/* Tablet and desktop styles */

@media (min-width:768px) {
  .form-container {
    width: 80vw;
    height: 70vh;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .date-time-group {
    gap: 20px;
  }

  .calendar-switch {
    margin-top: 10px;
  }

  .gender-switch {
    margin-bottom: 10px;
  }
}

.captcha-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.captcha-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.captcha-buttons {
  margin-top: 15px;
}

.captcha-buttons button {
  padding: 5px 15px;

}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import axios from '@/utils/requestAPI.js'
export default new Vuex.Store({
    state: {
        userInfo: '',
        askTimes: 0
    },

    mutations: {
        SET_USER_INFO(state, value) {
            state.userInfo = value
        },

        SET_ASK_TIME(state, value) {
            state.askTimes = value
        }
    },

    actions: {
        async newUser({ commit }, userForm) {
            const response = await axios.post('/ssme/newUser', userForm)
            commit('SET_USER_INFO', response.data)
            window.localStorage.setItem("ssm-info", response.data)
        },

        async getAskTime({ commit }, userid) {
            const response = await axios.get(`/ssme/getAskTime/${userid}`)
            commit('SET_ASK_TIME', response.data)
        },

        async deCreaseAskTime({ commit }, userid) {
            const response = await axios.get(`/ssme/deCreaseAskTime/${userid}`)
            commit('SET_ASK_TIME', response.data)
        }
    },
})
<template>
    <div class="marquee-container">
        <div class="marquee-content" :style="{ transform: `translateX(${position}px)` }">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Marquee',
    props: {
        text: {
            type: String,
            required: true
        },
        speed: {
            type: Number,
            default: 200
        }
    },
    data() {
        return {
            position: 0,
            containerWidth: 0,
            contentWidth: 0
        }
    },
    mounted() {
        this.containerWidth = this.$el.offsetWidth
        this.contentWidth = this.$el.firstChild.offsetWidth
        this.position = this.containerWidth
        this.animate()
    },
    methods: {
        animate() {
            this.position--
            if (this.position < -this.contentWidth) {
                this.position = this.containerWidth
            }
            requestAnimationFrame(this.animate)
        }
    }
}
</script>

<style scoped>
.marquee-container {
    position: absolute;
    top: 0.5vh;
    left: 0;
    width: 100%;
    overflow: hidden;
    background-color: floralwhite;
    color: rgb(135, 65, 65);
    padding: 10px 0;
    z-index: 999;
    font-size: 13px;
    line-height: 13px;
    opacity: 0.8;
}

.marquee-content {
    display: inline-block;
    white-space: nowrap;
}
</style>
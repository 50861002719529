<template>
    <div class="chart-container">
        <div ref="barChart" :style="{ width: '100%', height: '11.5vh' }"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    name: 'BarChart',
    data() {
        return {
            chart: null
        }
    },
    props: {
        // 可以通过props传入数据
        chartData: {
            type: Object,
            default: () => ({
                xData: ['Mon', 'Tue', 'Wed', 'Thu'],
                yData: [120, 200, 150, 80]
            })
        }
    },
    mounted() {
        this.initChart()
        // 监听窗口大小变化，重新调整图表大小
        window.addEventListener('resize', this.resizeChart)
    },
    beforeDestroy() {
        // 组件销毁前移除事件监听
        window.removeEventListener('resize', this.resizeChart)
        // 销毁图表实例
        if (this.chart) {
            this.chart.dispose()
            this.chart = null
        }
    },
    methods: {
        initChart() {
            // 初始化图表
            this.chart = echarts.init(this.$refs.barChart)
            // 设置图表配置项
            const option = {
                title: {
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: this.chartData.xData,
                    axisTick: {
                        show: false  // 同样可以隐藏x轴的刻度线
                    },
                    axisLabel: {
                        color: '#FFFFFF',  // x轴文字颜色
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false  // 隐藏刻度标签
                    }
                },
                series: [
                    {
                        name: '数值',
                        type: 'bar',
                        barWidth: '30%',  // 设置柱子宽度，可以用像素值'20px'或百分比'40%'
                        barGap: '50%',     // 设置柱子之间的间距，默认是'30%'
                        data: this.chartData.yData,
                        itemStyle: {
                            color: function (params) {
                                if (params.value < 5) {
                                    return '#FAFAD2';
                                }
                                else if (params.value >= 5 && params.value < 8) {
                                    return '#FFFACD';
                                } else if (params.value >= 8 && params.value < 13) {
                                    return '#FFFF00';
                                }else if (params.value >= 13 && params.value < 16) {
                                    return '#FF4500';
                                }
                                return '#FF0000';
                            }
                        }
                    }
                ],
                grid: {
                    left: '1%',     // 减小左边距
                    right: '1%'     // 减小右边距
                }
            }
            this.chart.setOption(option)
        },
        resizeChart() {
            if (this.chart) {
                this.chart.resize()
            }
        }
    },
    watch: {
        // 监听数据变化，更新图表
        chartData: {
            deep: true,
            handler(newValue) {
                this.$nextTick(() => {
                    this.initChart()
                })
            }
        }
    }
}
</script>

<style scoped>
</style>
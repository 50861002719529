<!-- ChatBot.vue -->
<template>
    <div class="chat-container">
        <!-- 聊天消息区域 -->
        <div class="chat-messages" ref="messageContainer">
            <div v-for="(message, index) in messages" :key="index" class="message-item" :class="message.type">
                <div class="avatar" v-if="message.type === 'received'" @click="goback">🤖</div>
                <div class="message-content" v-if="message.type === 'received'">{{ message.content }}</div>
                <div class="avatarsent" v-if="message.type === 'sent'">
                    <span class="iconfont icon-nvsheng sender"></span>
                </div>
                <div class="message-content" v-if="message.type === 'sent'">{{ message.content }}</div>
            </div>
        </div>

        <!-- 选择器和发送按钮区域 -->
        <div class="input-area">
            <div class="cascader-wrapper">
                <div class="cascader">
                    <div class="cascader-column">
                        <div v-for="item in options" :key="item.value" class="cascader-item"
                            :class="{ active: selected[0] === item.value }" @click="selectItem(item, 0)">
                            {{ item.label }}
                        </div>
                        <div class="want_more" @click="toOutLay">
                            <svg class="luoxuanrobot" aria-hidden="true">
                                <use xlink:href="#icon-white-Strip"></use>
                            </svg>
                            了解更多
                        </div>
                    </div>

                    <div class="cascader-column" v-if="secondOptions.length">
                        <div v-for="item in secondOptions" :key="item.value" class="cascader-item"
                            :class="{ active: selected[1] === item.value }" @click="selectItem(item, 1)">
                            {{ item.label }}
                        </div>
                    </div>

                    <div class="cascader-column" v-if="thirdOptions.length">
                        <div v-for="item in thirdOptions" :key="item.value" class="cascader-item"
                            :class="{ active: selected[2] === item.value }" @click="selectItem(item, 2)">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
            </div>
            <button class="send-btn" :class="{ 'send_button_active': sba }" @click="sendMessage"
                :disabled="!isValidSelection">发送</button>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/requestAPI.js'
import { Toast } from 'vant';
import { mapActions } from 'vuex'
export default {
    name: 'ChatBot',
    data() {
        return {
            sba: false,
            lefttimes: 0,
            man: false,
            woman: true,
            messages: [
                {
                    type: 'received',
                    content: '您好朋友，请选择您的问题吧。或者点我的头像可以回去呦。'
                }
            ],
            options: [
                {
                    value: 'wdtd',
                    label: '综合特点',
                    children: [
                        {
                            value: 'wdjs',
                            label: '简介'
                        },
                        {
                            value: 'wdsy',
                            label: '事业'
                        },
                        {
                            value: 'wdcf',
                            label: '财富'
                        },
                        {
                            value: 'wdgq',
                            label: '感情'
                        },
                        {
                            value: 'wdjz',
                            label: '健壮'
                        }
                    ]
                },
                {
                    value: 'gqwl',
                    label: '昨天明天',
                    children: [
                        {
                            value: '2012',
                            label: '2012年'
                        },
                        {
                            value: '2013',
                            label: '2013年'
                        },
                        {
                            value: '2014',
                            label: '2014年'
                        },
                        {
                            value: '2015',
                            label: '2015年'
                        },
                        {
                            value: '2016',
                            label: '2016年'
                        },
                        {
                            value: '2017',
                            label: '2017年'
                        },
                        {
                            value: '2018',
                            label: '2018年'
                        },
                        {
                            value: '2019',
                            label: '2019年'
                        },
                        {
                            value: '2020',
                            label: '2020年'

                        },
                        {
                            value: '2021',
                            label: '2021年'

                        },
                        {
                            value: '2022',
                            label: '2022年'
                        },
                        {
                            value: '2023',
                            label: '2023年'
                        },
                        {
                            value: '2024',
                            label: '2024年'

                        },
                        {
                            value: '2025',
                            label: '2025年'
                        },
                        {
                            value: '2026',
                            label: '2026年'
                        },
                        {
                            value: '2027',
                            label: '2027年'
                        },
                        {
                            value: '2028',
                            label: '2028年'
                        },
                        {
                            value: '2029',
                            label: '2029年'
                        },
                        {
                            value: '2030',
                            label: '2030年'
                        },
                        {
                            value: '2034',
                            label: '2034年'
                        },
                        {
                            value: '2035',
                            label: '2035年'
                        },
                        {
                            value: '2036',
                            label: '2035年'
                        }
                    ]
                },
                {
                    value: 'mnmy',
                    label: '某年某月',
                    children: [
                        {
                            value: '2012',
                            label: '2012年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2013',
                            label: '2013年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2014',
                            label: '2014年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2015',
                            label: '2015年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2016',
                            label: '2016年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2017',
                            label: '2017年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2018',
                            label: '2018年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2019',
                            label: '2019年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2020',
                            label: '2020年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2021',
                            label: '2021年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2022',
                            label: '2022年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2023',
                            label: '2023年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2024',
                            label: '2024年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2025',
                            label: '2025年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2026',
                            label: '2026年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2027',
                            label: '2027年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2028',
                            label: '2028年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2029',
                            label: '2029年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2030',
                            label: '2030年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2034',
                            label: '2034年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2035',
                            label: '2035年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                        {
                            value: '2036',
                            label: '2035年',
                            children: [
                                {
                                    value: '01',
                                    label: '1月'
                                },
                                {
                                    value: '02',
                                    label: '2月'
                                },
                                {
                                    value: '03',
                                    label: '3月'
                                },
                                {
                                    value: '04',
                                    label: '4月'
                                },
                                {
                                    value: '05',
                                    label: '5月'
                                },
                                {
                                    value: '06',
                                    label: '6月'
                                },
                                {
                                    value: '07',
                                    label: '7月'
                                },
                                {
                                    value: '08',
                                    label: '8月'
                                },
                                {
                                    value: '09',
                                    label: '9月'
                                },
                                {
                                    value: '10',
                                    label: '10月'
                                },
                                {
                                    value: '11',
                                    label: '11月'
                                },
                                {
                                    value: '12',
                                    label: '12月'
                                }
                            ]
                        },
                    ]
                },
            ],
            selected: [],
            currentOptions: []
        }
    },
    computed: {
        secondOptions() {
            if (this.selected[0]) {
                const firstOption = this.options.find(item => item.value === this.selected[0])
                return firstOption ? firstOption.children || [] : []
            }
            return []
        },
        thirdOptions() {
            if (this.selected[1]) {
                const secondOption = this.secondOptions.find(item => item.value === this.selected[1])
                return secondOption ? secondOption.children || [] : []
            }
            return []
        },
        isValidSelection() {
            if (this.selected[0] === 'mnmy') {
                return this.selected.length === 3
            }
            return this.selected.length === 2
        }
    },
    methods: {
        ...mapActions(['getAskTime']),
        ...mapActions(['deCreaseAskTime']),
        toOutLay() {
            this.$router.push({
                name: 'Outlay'
            })
        },
        async askMeTheLiebe() {
            let res = await axios.get(`/ssme/askMeTheLiebe/${this.$store.state.userInfo}`)
            await this.deCreaseAskTime(this.$store.state.userInfo)
            return res

        },

        async askMeTheGene() {
            let res = await axios.get(`/ssme/askMeTheGene/${this.$store.state.userInfo}`)
            await this.deCreaseAskTime(this.$store.state.userInfo)
            return res

        },

        async askMeTheGesu() {
            let res = await axios.get(`/ssme/askMeTheGesund/${this.$store.state.userInfo}`)
            await this.deCreaseAskTime(this.$store.state.userInfo)
            return res
        },

        async askMeTheFort() {
            let res = await axios.get(`/ssme/askMeTheFort/${this.$store.state.userInfo}`)
            await this.deCreaseAskTime(this.$store.state.userInfo)
            return res
        },

        async askMeTheCareer() {
            let res = await axios.get(`/ssme/askMeTheCareer/${this.$store.state.userInfo}`)
            await this.deCreaseAskTime(this.$store.state.userInfo)
            return res
        },

        async askMeTheYear(year) {
            let res = await axios.get(`/ssme/askMeTheYear/${this.$store.state.userInfo}/${year}`)
            await this.deCreaseAskTime(this.$store.state.userInfo)
            return res
        },

        async askMeTheMonth(year) {
            let res = await axios.get(`/ssme/askMeTheMonth/${this.$store.state.userInfo}/${year}`)
            await this.deCreaseAskTime(this.$store.state.userInfo)
            return res
        },

        goback() {
            this.$router.push({
                name: 'Chat'
            })
        },

        selectItem(item, level) {
            this.selected = this.selected.slice(0, level)
            this.selected[level] = item.value
            this.currentOptions[level] = item
            if (level === 0) {
                this.currentOptions.splice(1, 2)
            }
        },
        async sendMessage() {
            this.sba = true;
            setTimeout(() => {
                this.sba = false;
            }, 300);
            if (this.$store.state.askTimes <= 0) {
                Toast('宝宝，提问次数用完了，每天登陆会送一次哟。');
                return
            }
            if (!this.isValidSelection) return
            // 构建用户选择的完整路径文本
            const selectedPath = this.currentOptions.map(item => item.label).join(' > ')
            // 添加用户消息
            this.messages.push({
                type: 'sent',
                content: selectedPath
            })
            let response;
            if (this.selected[0] === "wdtd") {
                if (this.selected[1] === "wdsy") {
                    response = await this.askMeTheCareer();
                    this.messages.push({
                        type: 'received',
                        content: "您的第一职业大概是" + response.data.cata1 + "的" + response.data.level1
                    })
                    this.messages.push({
                        type: 'received',
                        content: "您的第二职业大概是" + response.data.cata2 + "的" + response.data.level2
                    })
                } else if (this.selected[1] === "wdcf") {
                    response = await this.askMeTheFort();
                    this.messages.push({
                        type: 'received',
                        content: "您的第一收入类型大概是" + response.data.geld1
                    })
                    this.messages.push({
                        type: 'received',
                        content: "您的第二收入类型大概是" + response.data.geld2
                    })
                } else if (this.selected[1] === "wdgq") {
                    response = await this.askMeTheLiebe();
                    this.messages.push({
                        type: 'received',
                        content: response.data.liebe1
                    })
                    this.messages.push({
                        type: 'received',
                        content: response.data.liebe2
                    })
                } else if (this.selected[1] === "wdjz") {
                    response = await this.askMeTheGesu();
                    this.messages.push({
                        type: 'received',
                        content: response.data.gesu1
                    })
                    this.messages.push({
                        type: 'received',
                        content: response.data.gesu2
                    })
                } else if (this.selected[1] === "wdjs") {
                    response = await this.askMeTheGene();
                    this.messages.push({
                        type: 'received',
                        content: response.data.gene1
                    })
                    this.messages.push({
                        type: 'received',
                        content: response.data.gene2
                    })
                }
            } else if (this.selected[0] === "gqwl") {
                response = await this.askMeTheYear(this.selected[1]);
                this.messages.push({
                    type: 'received',
                    content: response.data.field1
                })
                this.messages.push({
                    type: 'received',
                    content: response.data.field2
                })
            } else {
                response = await this.askMeTheMonth(this.selected[1] + "-" + this.selected[2]);
                this.messages.push({
                    type: 'received',
                    content: response.data.field1
                })
                this.messages.push({
                    type: 'received',
                    content: response.data.field2
                })
            }
            console.log(this.messages.length)
            if (this.messages.length == 4) {
                this.messages.push({
                    type: 'received',
                    content: "字数较多时，会随机显示一部分内容，所以每次提问都可能有不一样的答案呀。"
                })
            }
            setTimeout(() => {
                // this.selected = []
                // this.currentOptions = []
                // 滚动到底部
                this.$nextTick(() => {
                    this.scrollToBottom()
                })
            }, 500)
        },
        scrollToBottom() {
            const container = this.$refs.messageContainer
            container.scrollTop = container.scrollHeight
        },
    },
    beforeCreate() {
        if (this.$store.state.userInfo === '' && !window.localStorage.getItem('ssm-info')) {
            this.$router.push({
                name: 'info'
            })
        }
    },
    created() {
    }
}
</script>

<style scoped>
.chat-container {
    height: 90vh;
    flex-direction: column;
    background-color: #f5f5f5;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
}

.chat-messages {
    overflow-y: auto;
    padding-top: 4vh;
    flex: 1;
}

.message-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.message-item.sent {
    flex-direction: row-reverse;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.message-content {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.sent .message-content {
    background-color: #95ec69;
}

.input-area {
    background-color: white;
}

.cascader-wrapper {
    margin-bottom: 10px;
}

.cascader {
    display: flex;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 4px;
}

.cascader-column {
    flex: 1;
    border-right: 1px solid #eee;
    height: 200px;
    overflow-y: auto;
}

.cascader-column:last-child {
    border-right: none;
}

.cascader-item {
    padding: 10px;
    cursor: pointer;
}

.cascader-item.active {
    background-color: #736e6ea2;
    color: #ffffff;
}

.send-btn {
    width: 100%;
    padding: 10px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.send-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.avatarsent {
    display: flex;
}

.sender {
    align-items: right;
    line-height: 5vh;
}

.luoxuanrobot {
    width: 1.5em;
    height: 1.5em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    animation: spining 3s linear infinite;
    font-size: 16px;
}

.want_more {
    width: 35vw;
    border-radius: 20px;
    padding: 2px;
    margin-top: 20px;
    font-size: 20px;
    background-color: #d0a223;
    max-width: 200px;
    color: #ffffff;
}

.send_button_active {
    background-color: #e0e0e0;
    transform: scale(0.95);
}
</style>
<template>
  <div id="app">
    <Header />
    <div class="main_content">
      <Marquee text="本网站的内容仅供娱乐，不具参考和指导意义，如有雷同纯属巧合。么么哒!" :speed="30" />
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Marquee from './components/Marquee.vue'


export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Marquee
  }
}
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

#app {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100); /* 备用方案 */ /* 动态视口高度 */
}

.main_content {
  margin: 0 auto;
  flex: 1;
  width: 100%;
  position: relative;
  min-height: 90vh;
}

p{
  margin: 0;
  padding: 0; 
  text-indent: 1em; 
  box-sizing: border-box;
  font-weight: 500;
}


// @media (max-width:768px) {

//   Header {
//     height: 30px;
//   }

//   Footer {
//     height: 30px;
//   }
// }

// /* Tablet and desktop styles */

// @media (min-width:769px) and (max-width:1200px) {

//   Header {
//     height: 70px;
//   }

//   Footer {
//     height: 50px;
//   }
// }

// /* Large desktop styles */

// @media (min-width:1201px) {
//   Header {
//     height: 90px;
//   }

//   Footer {
//     height: 70px;
//   }
// }
</style>
